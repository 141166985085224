import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Contact from "../components/contactUs/contact";
import Footer from "../components/general/footer";
import Navbar from "../components/general/nav";
import Whatsapp from "../components/general/whatsapp";
import BlogPage from "../components/sustainability/blogPage";
import Content from "../pages/content";
import Destination from "../pages/destination";
import Faq from "../pages/faq";
import Flight from "../pages/flight";
import GroupDeparture from "../pages/groupDeparture/groupDepartures";
import GroupPackage from "../pages/groupDeparture/groupPackage";
import Home from "../pages/home";
import NotFound from "../pages/notFound";
import OnlineRequesting from "../pages/onlineRequesting";
// for testing
import Playground from "../pages/playground/playground";
import Review from "../pages/review";
// import Packages from "../pages/packages";
import Sustainability from "../pages/sustainability";
import TailoredMadeTrip from "../pages/tailoredMadeTrip";
import Package from "../pages/tourPackage/package";
import TourPackages from "../pages/tourPackage/tourPackages";
import ToTop from "./scrollToTop";

const MainLayout = ({ children, isOpen, setIsOpen }) => {
  return (
    <>
      <Navbar />
      <div className="min-h-[63.5vh] pt-20">{children}</div>
      <Footer />
    </>
  );
};

const Routing = () => {
  return (
    <Router>
      <ToTop />

      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        {/* Content */}
        <Route
          exact
          path="/content/:contentId"
          element={
            <MainLayout>
              <Content />
            </MainLayout>
          }
        />

        {/* package */}
        <Route
          exact
          path="/packages"
          element={
            <MainLayout>
              <TourPackages />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/package/:packageSlug"
          element={
            <MainLayout>
              <Package />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/package/:packageSlug/request"
          element={
            <MainLayout>
              <OnlineRequesting />
            </MainLayout>
          }
        />

        {/* group package */}
        <Route
          exact
          path="/groupDeparture"
          element={
            <MainLayout>
              <GroupDeparture />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/groupDeparture/:packageSlug"
          element={
            <MainLayout>
              <GroupPackage />
            </MainLayout>
          }
        />
        {/* <Route
          exact
          path="/tourPackages"
          element={
            <MainLayout>
              <Package />
            </MainLayout>
          }
        /> */}

        <Route
          exact
          path="/contact"
          element={
            <MainLayout>
              <Contact />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/tailorMadeTrip"
          element={
            <MainLayout>
              <TailoredMadeTrip />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/flight"
          element={
            <MainLayout>
              <Flight />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/faq"
          element={
            <MainLayout>
              <Faq />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/review/:reviewId"
          element={
            <MainLayout>
              <Review />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/destination/:destinationID"
          element={
            <MainLayout>
              <Destination />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/sustainability"
          element={
            <MainLayout>
              <Sustainability />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/sustainability/:blogId"
          element={
            <MainLayout>
              <BlogPage />
            </MainLayout>
          }
        />

        <Route exact path="/playground" element={<Playground />} />

        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Whatsapp />
      <Playground />
    </Router>
  );
};

export default Routing;
