// import parse from "html-react-parser";
import React from "react";
import { IoCalendarNumberOutline } from "react-icons/io5";

import Memorable from "../../components/tourPackage/memorable";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";
import Landing from "../tourPackage/landing";

const GroupDeparture = () => {
  const { data: packages } = useAxiosAuth(`GroupDeparture`);
  console.log(packages);
  return (
    <>
      <Landing />

      <div className="bg-white">
        <div className="xl:w-[90vw] px-5 mx-auto py-12">
          <h1 className="text-[#1D306D] text-5xl pb-5 ">
            Group Departure Packages
          </h1>
          <p className="paragraph capitalize">
            See what our packages have to offer
          </p>
          <div className="grid sm:grid-cols-2 md:grid-cols-1 gap-4">
            {packages.length > 0 ? (
              packages.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row gap-8 bg-[#ffffff] rounded-2xl mt-4 p-4 md:p-8 border"
                >
                  {item.package?.image1 ? (
                    <img
                      className="rounded-xl w-full md:min-w-[20vw] md:w-[20vw] h-[15vw] object-cover"
                      src={`${BASE_URL}/${item.package.image1.filePath}`}
                      alt={item.package.image1.altInfo}
                    />
                  ) : (
                    <img
                      className="rounded-xl w-full md:min-w-[20vw] md:w-[20vw] h-[15vw] object-contain border-2 px-5"
                      src={require("../../assets/images/DrukairLogo.png")}
                      alt={"thumbnail unavailable"}
                    />
                  )}

                  <div className="mt-4 md:mt-0">
                    <div className="flex items-center leading-8">
                      <div>
                        <h1 className="text-2xl font-bold">
                          {item.package.title}
                        </h1>
                        <p className="flex items-center py-1 text-secondary">
                          <IoCalendarNumberOutline />
                          &nbsp;{item.package.tripDays} Days /{" "}
                          {item.package.tripNights}{" "}
                          {item.package.tripNights === 1 ? "Night" : "Nights"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="text-gray-500 text-sm group inline"
                      dangerouslySetInnerHTML={{
                        __html: item.package.description.slice(0, 400) + "...",
                      }}
                    />
                    <div className="flex gap-4 pt-3">
                      <button className="p-button w-fit">Book Package</button>
                      <a
                        href={`/GroupDeparture/${item.id}`}
                        className="s-button w-fit"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="bg-red-100 p-5 rounded-lg mt-5">
                Sorry, No Group Departure Packages available at the moment.
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>

      <Memorable />
    </>
  );
};

export default GroupDeparture;
