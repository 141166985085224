import parse from "html-react-parser";
import React from "react";
import { FaRegUser, FaUser } from "react-icons/fa";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import specialSVG from "../../assets/images/specialSvg.svg";
import "../../styles/styles.css";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

const BlogPage = () => {
  const { blogId } = useParams();
  // console.log(blogId);

  const { data: blogs } = useAxiosAuth("blogpost");

  const activeBlog = blogs ? blogs.find((blog) => blog.id === blogId) : null;
  // console.log(activeBlog && activeBlog.thumbnail.filePath);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`absolute top-[-10vh] right-[5vw] border-2 p-2 bg-[#DF5C26] text-4xl text-white rounded-2xl cursor-pointer`}
        style={{ display: "block" }}
        onClick={onClick}
      >
        <MdChevronRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`absolute top-[-10vh] right-[25vw] md:right-[13vw] border-2 p-2 border-[#DF5C26] text-4xl text-[#DF5C26] rounded-2xl cursor-pointer`}
        style={{ display: "block" }}
        onClick={onClick}
      >
        <MdChevronLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-[#f7f7f7]">
      {/* TOP section */}
      <div className=" bg-cover">
        {/* Image */}
        <div className="content-image-overlay">
          {activeBlog ? (
            <img
              src={`${BASE_URL}/${activeBlog.thumbnail.filePath}`}
              alt="Sustainability Background"
              className="absolute -top-[1px] h-[60vh] w-full object-cover "
            />
          ) : (
            <img
              src={require("../../assets/images/bhutan-fourth.png")}
              alt="Sustainability Background"
              className="absolute -top-[1px] h-[60vh] w-full object-cover "
            />
          )}
          <div className="overlay h-[60vh] top-0"></div>
        </div>
        <div className="relative -top-20 h-[61vh]">
          <img
            className="absolute bottom-0 left-0 w-full"
            src={specialSVG}
            alt="Special SVG"
          />

          {activeBlog && (
            <div className="grid grid-cols-1 content-center h-full 2xl:w-[90vw] lg:px-10 px-5 lg:pt-0 pt-20 mx-auto text-white ">
              <h1 className="xl:text-5xl text-4xl font-bold text-white">
                {activeBlog.title}
              </h1>
              <h2 className="text-lg pt-2 pb-8 text-white">
                <FaUser className="inline" />
                &nbsp; Authored by: {activeBlog.author}
              </h2>
            </div>
          )}
        </div>
      </div>

      {/* Blog Section */}
      {activeBlog && (
        <div className="blog 2xl:w-[90vw] lg:px-10 px-5 mx-auto">
          <div className="pb-10 text-justify">
            <div className="table-container text-left md:text-justify">
              {parse(activeBlog.content)}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-10">
            <div>
              {activeBlog.image1 ? (
                <img
                  src={`${BASE_URL}/${activeBlog.image1.filePath}`}
                  alt={activeBlog.image1.altInfo}
                  className="w-full object-cover xl:h-[50vh] lg:h-[40vh] md:h-[30vh] rounded-xl"
                />
              ) : (
                <img
                  src={require(`../../assets/images/bhutan-third.png`)}
                  alt="unavailable"
                  className="w-full object-cover xl:h-[50vh] lg:h-[40vh] md:h-[30vh] rounded-xl"
                />
              )}
            </div>
            <div>
              {activeBlog.image2 ? (
                <img
                  src={`${BASE_URL}/${activeBlog.image2.filePath}`}
                  alt={activeBlog.image2.altInfo}
                  className="w-full object-cover xl:h-[50vh] lg:h-[40vh] md:h-[30vh] rounded-xl"
                />
              ) : (
                <img
                  src={require(`../../assets/images/bhutan-fourth.png`)}
                  alt="unavailable"
                  className="w-full object-cover xl:h-[50vh] lg:h-[40vh] md:h-[30vh] rounded-xl"
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 2xl:w-[90vw] lg:px-10 px-5 mx-auto pb-10">
        <h1 className="lg:text-5xl text-3xl mb-10 text-primary">Other Blogs</h1>

        {blogs.length > 0 && (
          <Slider {...settings}>
            {blogs.map((item, index) => (
              <a
                href={`./${item.id}`}
                key={index}
                className="rounded-xl text-center shadow-lg max-w-[96%] mx-auto mb-4"
              >
                <div className="h-[28vh]">
                  <img
                    className="rounded-t-xl object-cover w-full h-full"
                    src={`${BASE_URL}/${item.thumbnail.filePath}`}
                    alt={item.thumbnail.alt}
                  />
                </div>

                <div className="flex flex-col gap-5 p-6">
                  <h1 className="text-black font-semibold text-left text-xl">
                    {item.title}
                  </h1>
                  <span className="text-[#DF5C26] flex items-center">
                    <FaRegUser className="mr-2" /> {item.author}
                  </span>
                  {/* <div className="flex w-full justify-between text-left">
                    <div>
                      <p className="text-black2">
                        {parse(item.content.substring(0, 150) + "...")}
                      </p>
                    </div>
                  </div> */}
                </div>
              </a>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
