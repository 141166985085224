import React from "react";

import Banner from "../components/home/banner";
import Choose from "../components/home/choose";
import Inspiration from "../components/home/inspiration";
import Partners from "../components/home/partners";
import Serving from "../components/home/serving";
import Special from "../components/home/special";
import SupportedBy from "../components/home/supportedBy";

const Home = () => {
  return (
    <div className="bg-white">
      <Special />
      <Choose />
      <Serving />
      <Inspiration />
      <Banner />
      <Partners />
      <SupportedBy />
    </div>
  );
};

export default Home;
