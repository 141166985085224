import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { BASE_URL } from "../utils/axios/axios";
import useAxiosAuth from "../utils/axios/useAxiosAuth";

const OnlineRequesting = () => {
  const { data: foodPreferences } = useAxiosAuth(`FoodPreference`);
  const { data: country } = useAxiosAuth(`Country`);

  // Package Details
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [countrySelection, setCountrySelection] = useState("");
  const [noOfAdult, setNoOfAdult] = useState("");
  const [noOfChildren, setNoOfChildren] = useState(0);
  const [noOfInfant, setNoOfInfant] = useState(0);
  const [foodPreference, setFoodPreference] = useState([]);
  const [specialRequest, setSpecialRequest] = useState("");

  // Personal Details
  const [salutations, setSalutations] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [documentTypeSelection, setDocumentTypeSelection] = useState("");

  // Emergency Details
  const [eSalutations, setESalutations] = useState("");
  const [eFirstName, setEFirstName] = useState("");
  const [eLastName, setELastName] = useState("");
  const [eAddress, setEAddress] = useState("");
  const [eNationality, setENationality] = useState("");
  const [eMobileNumber, setEMobileNumber] = useState("");
  const [eEmail, setEEmail] = useState("");
  const [eRelation, setERelation] = useState("");

  // Do you need Flight?
  const [flightConfirm, setFlightConfirm] = useState(false);

  // console.log(flightConfirm);
  useEffect(() => {
    const arrivalDateInput = document.getElementById("arrivalDate");
    if (arrivalDateInput) {
      arrivalDateInput.addEventListener("focus", function () {
        this.type = "date";
      });
      arrivalDateInput.addEventListener("blur", function () {
        this.type = "text";
      });
    }
  }, []);

  useEffect(() => {
    const departureDateInput = document.getElementById("departureDate");
    if (departureDateInput) {
      departureDateInput.addEventListener("focus", function () {
        this.type = "date";
      });
      departureDateInput.addEventListener("blur", function () {
        this.type = "text";
      });
    }
  }, []);

  const submitOnlineRequest = (event) => {
    event.preventDefault();
    const payload = {
      arrivalDate: arrivalDate,
      departureDate: departureDate,
      noOfAdults: noOfAdult,
      noOfChildren: noOfChildren,
      // TODO: ryuu
      noOfInfant: noOfInfant,
      countryId: countrySelection,
      requestFoodPreferences: foodPreference,
      specialRequest: specialRequest,
      personalDetailsSalutation: salutations,
      personalDetailsFirstName: firstName,
      personalDetailsLastName: lastName,
      personalDetailsAddress: address,
      personalDetailsCountryId: nationality,
      personalDetailsPhoneNumber: mobileNumber,
      personalDetailsEmail: email,
      personalDetailsDocumentTypeSelection: documentTypeSelection,
      emergencyDetailsSalutation: eSalutations,
      emergencyDetailsFirstName: eFirstName,
      emergencyDetailsLastName: eLastName,
      emergencyDetailsAddress: eAddress,
      emergencyDetailsCountryId: eNationality,
      emergencyDetailsEmail: eEmail,
      emergencyDetailsPhoneNumber: eMobileNumber,
      relationWithContactPerson: eRelation,
      flightRequired: flightConfirm,
      isTermsAndConditionsAccepted: true,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/onlineRequest/`,
      headers: {
        Authorization: `Basic ${window.btoa("frontend:WWEQgN9.%AAw]GfR")}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        setArrivalDate("");
        setDepartureDate("");
        setCountrySelection("");
        setNoOfAdult("");
        setNoOfChildren("");
        setFoodPreference("");
        setSpecialRequest("");
        setSalutations("");
        setFirstName("");
        setLastName("");
        setAddress("");
        setNationality("");
        setMobileNumber("");
        setEmail("");
        setDocumentTypeSelection("");
        setESalutations("");
        setEFirstName("");
        setELastName("");
        setEAddress("");
        setENationality("");
        setEMobileNumber("");
        setERelation("");
        setFlightConfirm("");

        toast.success("Package request recorded succesfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Package request has failed!");
      });
  };
  const addOrRemove = (name) => {
    const newfoodPreference = [...foodPreference];
    const index = newfoodPreference.indexOf(name);
    if (index === -1) {
      newfoodPreference.push(name);
    } else {
      newfoodPreference.splice(index, 1);
    }
    setFoodPreference(newfoodPreference);
  };
  return (
    <div className="xl:w-[90vw] px-5 mx-auto pb-10">
      <ToastContainer />

      <div className="text-[#1D306D] mx-auto text-center py-10">
        <h1 className="text-[56px]">Online Requesting</h1>
      </div>

      <form
        action=""
        className="text-[#4B4F52] w-full"
        onSubmit={submitOnlineRequest}
      >
        <div className="shadow-xl border rounded-lg p-5 mb-7">
          <span className="text-[18px]">Package Details</span>
          <br />
          <br />
          <br />

          <div className="md:grid md:grid-cols-2  gap-8">
            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                name="arrivalDate"
                id="arrivalDate"
                type="text"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 focus:type peer"
                placeholder=""
                value={arrivalDate}
                onChange={(e) => {
                  setArrivalDate(e.target.value);
                }}
                required
              />
              <label
                htmlFor="arrivalDate"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Arrival Date *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                name="departureDate"
                id="departureDate"
                type="text"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 focus:type peer"
                placeholder=""
                value={departureDate}
                onChange={(e) => {
                  setDepartureDate(e.target.value);
                }}
                required
              />
              <label
                htmlFor="departureDate"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Departure Date *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <select
                name="countrySelection"
                id="countrySelection"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                value={countrySelection}
                onChange={(e) => {
                  setCountrySelection(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>
                {country.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="countrySelection"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Destination *
              </label>
            </div>

            <div className="grid md:grid-cols-3 grid-cols-1 xl:col-span-1 md:col-span-2 col-span-1 gap-4">
              <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
                <select
                  name="noOfAdult"
                  id="noOfAdult"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={noOfAdult}
                  onChange={(e) => {
                    setNoOfAdult(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfAdult"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Adult (Age above 12)*
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
                <select
                  name="noOfChildren"
                  id="noOfChildren"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={noOfChildren}
                  onChange={(e) => {
                    setNoOfChildren(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfChildren"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Children (Age 2-12)
                </label>
              </div>

              <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
                <select
                  name="noOfInfant"
                  id="noOfInfant"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={noOfInfant}
                  onChange={(e) => {
                    setNoOfInfant(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfInfant"
                  className="peer-focus:font-medium absolute text-[14px] text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Infants (Age below 2)
                </label>
              </div>
            </div>

            <div className="w-full mb-8 md:mb-5 col-span-2 md:grid  md:grid-cols-4 gap-4">
              <span className="text-[#8A94B2] col-span-4">
                Food Preferences
              </span>
              {foodPreferences.map((item, index) => (
                <div key={index}>
                  <input
                    name="foodPreferences"
                    id={item.id}
                    type="checkbox"
                    className="outline outline-1 outline-gray-300 rounded-sm"
                    value={item.id}
                    onClick={() => addOrRemove(item.id)}
                  />
                  <label htmlFor={item.id} className="px-2">
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md col-span-2">
              <textarea
                type="textarea"
                name="specialRequest"
                id="specialRequest"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={specialRequest}
                onChange={(e) => {
                  setSpecialRequest(e.target.value);
                }}
              />
              <label
                htmlFor="specialRequest"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Special Request
              </label>
            </div>
          </div>
        </div>

        {/* Personal Detail Section */}
        <div className="shadow-xl border rounded-lg p-5 mb-7">
          <span className="text-[18px]">Personal Details</span>
          <br />
          <br />
          <br />
          <div className="md:grid md:grid-cols-2 gap-8">
            <div className="flex gap-5">
              <div className="flex-none w-13% relative z-0 md:mb-5 group border border-gray-300 rounded-md">
                <select
                  name="salutations"
                  id="salutations"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={salutations}
                  onChange={(e) => {
                    setSalutations(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="0">Mr.</option>
                  <option value="1">Miss.</option>
                  <option value="2">Mrs.</option>
                </select>
                <label
                  htmlFor="salutations"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  Salutations *
                </label>
              </div>
              <div className="relative z-0 w-full md:mb-5 group border border-gray-300 rounded-md">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  required
                />
                <label
                  htmlFor="firstName"
                  className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
                >
                  First Name *
                </label>
              </div>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="lastName"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Last Name *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="address"
                id="address"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                required
              />
              <label
                htmlFor="address"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Address *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <select
                name="nationality"
                id="nationality"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                value={nationality}
                onChange={(e) => {
                  setNationality(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>
                {country.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="nationality"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Nationality *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="number"
                name="contactNo"
                id="contactNo"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                }}
                required
              />
              <label
                htmlFor="contactNo"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Contact No. *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emailAddress"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Email Address *
              </label>
            </div>

            {/* <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <select
                name="documentTypeSelection"
                id="documentTypeSelection"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                value={documentTypeSelection}
                onChange={(e) => {
                  setDocumentTypeSelection(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>

                <option value="destination1">Passport</option>
                <option value="destination2">Citizenship</option>
              </select>
              <label
                htmlFor="documentTypeSelection"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Select Document Type *
              </label>
            </div> */}
          </div>
        </div>

        {/* Emergency Detail Section */}
        <div className="shadow-xl border rounded-lg p-5 mb-7">
          <span className="text-[18px]">Emergency Details</span>
          <br />
          <br />
          <br />

          <div className="md:grid md:grid-cols-2 gap-8">
            <div className="flex gap-5">
              <div className="flex-none w-13% relative z-0 md:mb-5 group border border-gray-300 rounded-md">
                <select
                  name="emergencySalutations"
                  id="emergencySalutations"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={eSalutations}
                  onChange={(e) => {
                    setESalutations(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>

                  <option value="0">Mr.</option>
                  <option value="1">Miss.</option>
                  <option value="2">Mrs.</option>
                </select>
                <label
                  htmlFor="emergencySalutations"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  Salutations *
                </label>
              </div>

              <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
                <input
                  type="text"
                  name="emergencyFirstName"
                  id="emergencyFirstName"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                  placeholder=""
                  value={eFirstName}
                  onChange={(e) => {
                    setEFirstName(e.target.value);
                  }}
                  required
                />
                <label
                  htmlFor="emergencyFirstName"
                  className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
                >
                  First Name *
                </label>
              </div>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="emergencyLastName"
                id="emergencyLastName"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={eLastName}
                onChange={(e) => {
                  setELastName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emergencyLastName"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Last Name *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="emergencyAddress"
                id="addremergencyAddressess"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={eAddress}
                onChange={(e) => {
                  setEAddress(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emergencyAddress"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Address *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <select
                name="emergencyNationality"
                id="emergencyNationality"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                value={eNationality}
                onChange={(e) => {
                  setENationality(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>
                {country.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="emergencyNationality"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Nationality *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="number"
                name="emergencyContactNo"
                id="emergencyContactNo"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={eMobileNumber}
                onChange={(e) => {
                  setEMobileNumber(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emergencyContactNo"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Contact No. *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group border border-gray-300 rounded-md">
              <input
                type="text"
                name="emergencyRelation"
                id="emergencyRelation"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] dark:border-gray-600 focus:outline-none focus:ring-0 peer"
                placeholder=""
                value={eRelation}
                onChange={(e) => {
                  setERelation(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emergencyRelation"
                className="peer-focus:font-medium absolute px-4 text-[#8A94B2] dark:text-gray-400 duration-300 transform -translate-y-9 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#4B4F52] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-9 peer-focus:px-0"
              >
                Relation *
              </label>
            </div>
          </div>
        </div>

        {/* Flight Confirmation Section */}
        <div className="shadow-xl border rounded-lg p-5 mb-7">
          <span className="text-[18px]">Do you need Flight?</span>
          <br />
          <br />

          <div className="md:grid md:grid-cols-2 gap-8">
            <div className="relative z-0 w-full mb-5 group">
              <select
                name="flightConfirmationStatus"
                id="flightConfirmationStatus"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 dark:text-[#8A94B2] outline outline-1 outline-gray-300 rounded-md "
                value={flightConfirm}
                onChange={(e) => {
                  setFlightConfirm(e.target.value === "true");
                }}
                required
              >
                <option value={true}>Yes</option>
                <option value={false} selected>
                  No
                </option>
              </select>
            </div>
          </div>
        </div>

        <div className="pb-3">
          <input
            type="checkbox"
            name="termsAndCondition"
            id="termsAndCondition"
            className="outline outline-1 outline-gray-300 rounded-sm"
            required
          />
          <label htmlFor="termsAndCondition" className="px-2">
            Click here to indicate that you have read and agree to the{" "}
            <a
              href="/content/eaf0e462-ba33-43f6-9140-6eacf78d53a1"
              className="text-[#1D306D]"
            >
              Terms and Conditions.
            </a>
          </label>
        </div>

        <div className="w-[162px] mx-auto">
          <input
            type="submit"
            value="Book Now"
            className="w-full mx-auto bg-secondary text-white rounded-full py-4"
          />
        </div>
      </form>
    </div>
  );
};

export default OnlineRequesting;
