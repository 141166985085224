import { Accordion } from "flowbite-react";
import React from "react";
import { useParams } from "react-router-dom";

import pdf from "../assets/pdf/Tentative_Festival_Dates_2025.pdf";
import ImageRendering from "../components/destination/imageRendering";
import TourPackageCarousel from "../components/tourPackage/tourPackageCarousel";
import useAxiosAuth from "../utils/axios/useAxiosAuth";

const Destination = () => {
  const { destinationID } = useParams();
  const { data: destination } = useAxiosAuth(`Destination/${destinationID}`);
  const { data: packages } = useAxiosAuth(`Package`);
  const filteredPackages = packages.filter(
    (item) => item.destinationIds == destinationID
  );

  const data = {
    country: "Bhutan",
    title: "The Kingdom of Bhutan",
    festivalDates: pdf,
    description:
      "BHUTAN, known as 'The Land of thunder Dragon' is situated between India & Tibet and for centuries forbidden to westerners. Bhutan has preserved it's timeless traditions and ancestral way of life. Apart from the rich cultural heritage, it is a land of soaring scenery of paramount peaks. Sacred mountains often unnamed and unclimbed, soar above tropical forest and richly cultivated valley. Ancient legends are preserved in spectacular cliff-side monasteries and colorful festivals echo the unwavering Buddhist beliefs of the local people.",
    generalInfo: [
      "Anthem: The Thunder Dragon Kingdom",
      "Weather: Spring, Summer, Autumn, and Winter (Annually)",
      "Capital: Thimpu",
    ],
    visaReq: [
      "A digital copy of your passport. The passport must be valid for six months from the intended date of departure from Bhutan.",
      "A recent digital passport size photograph.",
    ],
    national: [
      "National Day: 17 December",
      "National Sport: Play Archery",
      "National Animal: Takin",
      "National Bird: Raven",
      "National Flower: Himalayan Blue Poppy",
      "National Food: Ema Datshi (Chilli Cheese)",
    ],
    faq: [
      {
        question: "Do I need a visa to enter Bhutan?",
        answer:
          "Visitors of all nationalities, except those from India, require a visa before entering Bhutan. Visitors from India are able to apply for a permit but are required to hold an Indian passport or an Indian voter ID card. ",
      },
      {
        question: "Is it safe to visit Bhutan?",
        answer:
          "Bhutan is a very safe place to visit, even if you’re travelling alone. There is very little crime experienced by locals or visitors, although we advise you to take care of yourself and your belongings. ",
      },
    ],
    boxInfo: [
      {
        title: "Area",
        data: "38,394 Sq.km.",
      },
      {
        title: "Population",
        data: "727,145 +",
      },
      {
        title: "Capital",
        data: "Thimphu",
      },
      {
        title: "Currency",
        data: "Ngultrum (BTN)",
      },
      {
        title: "Time Zone",
        data: "UTC+06 (BTT)",
      },
    ],
  };

  console.log(filteredPackages);
  return (
    <>
      <ImageRendering destination={destination} />

      {/* Country info */}
      {/* <section className="xl:w-[90vw] px-5 mx-auto z-10 relative pb-6">
        <div className="flex gap-8 md:justify-between pb-6">
          <h1 className="text-primary font-bold text-4xl">{data.title}</h1>
          <a
            href={data.festivalDates}
            target="_blank"
            rel="noreferrer"
            className="bg-secondary text-white rounded-full py-4 px-6"
          >
            Festival Dates
          </a>
        </div>
        <p className="text-xl text-[#8A94B2] ">{destination.description}</p>

        {/* general information
        <div className="pt-8">
          <h2 className="pb-2 inline-flex items-center gap-2">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="text-[#1D306D]"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
              ></path>
            </svg>
            <div className=" text-primary font-semibold md:text-2xl text-lg ">
              General Information
            </div>
          </h2>
          <ul className="text-gray-500 list-disc list-outside pl-12 leading-7">
            {data.generalInfo.map((info, index) => (
              <li key={index}>{info}</li>
            ))}
          </ul>
        </div>

        {/* Visa Requirements
        <div className="pt-8">
          <h2 className="pb-2 inline-flex items-center gap-2">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="text-[#1D306D]"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
              ></path>
            </svg>
            <div className=" text-primary font-semibold md:text-2xl text-lg ">
              Visa Requirements
            </div>
          </h2>
          <ul className="text-gray-500 list-disc list-outside pl-12 leading-7">
            {data.visaReq.map((info, index) => (
              <li key={index}>{info}</li>
            ))}
          </ul>
        </div>

        {/* Nationals
        <div className="pt-8">
          <h2 className="pb-2 inline-flex items-center gap-2">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="text-[#1D306D]"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
              ></path>
            </svg>
            <div className=" text-primary font-semibold md:text-2xl text-lg ">
              Nationals
            </div>
          </h2>
          <ul className="text-gray-500 list-disc list-outside pl-12 leading-7">
            {data.national.map((info, index) => (
              <li key={index}>{info}</li>
            ))}
          </ul>
        </div>

        {/* FAQ
        <div className="pb-10 pt-6">
          <div className="text-left pb-10">
            <h1 className="text-3xl font-bold text-primary capitalize">
              frequently asked questions
            </h1>
            {/* <h2 className="text-lg pt-2 pb-8 text-secondary">
            Get answers to common questions
          </h2>
          </div>
          <div className="FAQ">
            <Accordion>
              {data.faq.map((item, index) => (
                <Accordion.Panel key={index}>
                  <Accordion.Title className="text-primary bg-gray-50">
                    {item.question}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-gray-500">{item.answer}</p>
                  </Accordion.Content>
                </Accordion.Panel>
              ))}
            </Accordion>
          </div>
        </div>

        {/* Other Info
        <div className="lg:px-20 sm:pb-4 sm:pt-4 pb-5 bg-[#2b3c7633] rounded-md grid xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:divide-y-0 divide-y place-content-center gap-5">
          {data.boxInfo.map((info, index) => (
            <div key={index} className="text-center text-black sm:pt-0 pt-5">
              <span className="text-2xl font-semibold text-[#000] pb-2 inline-block">
                {info.data}
              </span>
              <br />
              {info.title}
            </div>
          ))}
        </div>
      </section> */}

      {/* Components */}
      {packages.length > 0 && (
        <TourPackageCarousel packages={filteredPackages} />
      )}
    </>
  );
};

export default Destination;
